import { useState, ReactNode, useMemo, useEffect } from 'react'

import Head from 'next/head'
import type { GetServerSideProps } from 'next/types'
import Link from 'next/link'
import { useRouter } from 'next/router'

import {
  Button,
  Checkbox,
  Typography,
  IconButton,
  InputAdornment,
  FormControlLabel,
  Stack,
  Box,
  BoxProps,
  useMediaQuery,
  styled,
  useTheme,
  FormControlLabelProps
} from '@mui/material'

import * as zod from 'zod'
import { useForm, Controller } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { useTranslation } from 'react-i18next'

import Icon from 'src/@core/components/icon'
import CustomTextField from 'src/@core/components/mui/text-field'
import { useSettings } from 'src/@core/hooks/useSettings'
import BlankLayout from 'src/@core/layouts/BlankLayout'

import { useAuth } from 'src/hooks/useAuth'
import { usePortalSettings } from 'src/hooks/usePortalSettings'

import { GDPRBanner } from 'src/components/settings/analytics/components'

import { getImageUrl } from 'src/utils/getImageUrl'
import { getGDPRLinks } from 'src/utils/settings/portal-settings/utils'

import themeConfig from 'src/configs/themeConfig'

import fetchMetaData, { DEFAULT_METADATA, MetaData } from '../../utils/fetchMetadata'

import { LargeBannerTypeId } from 'src/types/settings'

const StyledLogo = styled(Box)({
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  width: 48,
  height: 48
})

const ImageBox = styled(Box)<BoxProps>({
  flex: 1,
  display: 'flex',
  position: 'relative',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat'
})

const TopImageBox = styled(Box)<BoxProps>({
  flex: 1,
  display: 'flex',
  position: 'relative',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 50 % 0,
  aspectRatio: 4 / 1
})

const RightWrapper = styled(Box)<BoxProps>(({ theme }) => ({
  width: '100%',
  [theme.breakpoints.up('md')]: {
    maxWidth: 450
  },
  [theme.breakpoints.up('lg')]: {
    maxWidth: 600
  },
  [theme.breakpoints.up('xl')]: {
    maxWidth: 750
  }
}))

const LinkStyled = styled(Link)(({ theme }) => ({
  textDecoration: 'none',
  color: `${theme.palette.primary.main} !important`
}))

const StyledFormControlLabel = styled(FormControlLabel)<FormControlLabelProps>(({ theme }) => ({
  '& .MuiFormControlLabel-label': {
    color: theme.palette.text.secondary
  }
}))

const schema = zod.object({
  email: zod.string().min(1),
  password: zod.string().min(1)
})

const defaultValues =
  process.env.NODE_ENV !== 'production'
    ? {
        password: '1234',
        email: 'content_creator@technokert.ru'
      }
    : {
        password: '',
        email: ''
      }

interface FormData {
  email: string
  password: string
}

const LoginPage = ({ metaData }: { metaData: MetaData }) => {
  const [rememberMe, setRememberMe] = useState<boolean>(true)
  const [showPassword, setShowPassword] = useState<boolean>(false)

  const { t } = useTranslation('translation', { keyPrefix: 'PAGE.LOGIN' })
  const { t: tGlobal } = useTranslation('translation')
  const { portalSettings } = usePortalSettings()

  const router = useRouter()
  const { login, registerActivate, registerVerify } = useAuth()
  const theme = useTheme()
  const { settings } = useSettings()
  const isMediumScreen = useMediaQuery(theme.breakpoints.down('md'))

  useEffect(() => {
    if (router.query.activation_code) {
      registerActivate({ activation_code: router.query.activation_code as string })
    }

    if (router.query.verification_code) {
      registerVerify({ verification_code: router.query.verification_code as string })
    }
  }, [router.query.activation_code, router.query.verification_code])

  const [loginSettings, configuration, gdpr, design, portalDesignSettings] = useMemo(
    () => [
      portalSettings?.content_login_page_settings?.[0],
      portalSettings?.configuration?.[0],
      portalSettings?.settings_imprint_gdrp?.[0],
      portalSettings?.content_design_settings?.[0],
      portalSettings?.portal_design_images?.[0]
    ],
    [
      portalSettings?.content_login_page_settings,
      portalSettings?.configuration,
      portalSettings?.settings_imprint_gdrp,
      portalSettings?.content_design_settings,
      portalSettings?.portal_design_images
    ]
  )

  // ** Vars
  const { skin } = settings

  const {
    control,
    setError,
    handleSubmit,
    formState: { errors }
  } = useForm({
    defaultValues,
    mode: 'onBlur',
    resolver: zodResolver(schema)
  })

  const onSubmit = async (data: FormData) => {
    const { email, password } = data

    await login({ login: email, password }, () => {
      setError('email', {
        type: 'manual',
        message: t('VALIDATIONS.invalid_fields')
      })
    })
  }

  return (
    <>
      <Head>
        <title>{metaData.title}</title>
        <meta name='description' content={metaData.description} />
        <meta property='og:title' content={metaData.ogTitle} />
        <meta property='og:description' content={metaData.ogDescription} />
        <meta property='og:image' content={metaData.ogImage} />
      </Head>
      <Box className={!isMediumScreen ? 'content-right' : ''} sx={{ backgroundColor: 'background.paper' }}>
        {!isMediumScreen ? (
          <ImageBox
            sx={{
              borderRadius: skin === 'bordered' ? theme.spacing(4.5) : 0,
              backgroundImage: design?.wallpaper_login_page
                ? `url(${getImageUrl(design?.wallpaper_login_page)})`
                : `url('/app/images/defaultBackgroundImg.png')`
            }}
          />
        ) : null}

        <RightWrapper>
          {isMediumScreen && (
            <TopImageBox
              sx={{
                borderRadius: skin === 'bordered' ? theme.spacing(4.5) : 0,
                backgroundImage: design?.wallpaper_login_and_registration_mobile
                  ? `url(${getImageUrl(design?.wallpaper_login_and_registration_mobile)})`
                  : `url('/app/images/defaultBackgroundImg.png')`
              }}
            />
          )}

          <Box
            className={isMediumScreen ? 'content-center' : ''}
            sx={{
              p: [6, 12],
              height: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            <Box sx={{ width: '100%', maxWidth: 400 }}>
              {portalDesignSettings?.big_logo && (
                <StyledLogo
                  sx={{
                    backgroundImage: `url(${
                      portalDesignSettings?.big_logo
                        ? getImageUrl(portalDesignSettings?.big_logo)
                        : '/app/images/defaultBackgroundImg.png'
                    })`
                  }}
                />
              )}

              <Stack paddingY={theme.spacing(6.5)} gap={theme.spacing(1.5)}>
                {loginSettings?.show_title && (
                  <Typography variant='h3'>
                    {loginSettings?.title || `${t('title', { portalName: themeConfig.templateName })}! 👋🏻`}
                  </Typography>
                )}

                {loginSettings?.show_subtitle && (
                  <Typography variant='h5' sx={{ color: 'text.secondary' }}>
                    {loginSettings?.subtitle || `${t('subtitle')}`}
                  </Typography>
                )}

                {loginSettings?.show_description && (
                  <Typography variant='body1' sx={{ color: 'text.secondary' }}>
                    {loginSettings?.description}
                  </Typography>
                )}
              </Stack>

              <form noValidate onSubmit={handleSubmit(onSubmit)}>
                <Stack gap={theme.spacing(4)}>
                  <Controller
                    name='email'
                    control={control}
                    rules={{ required: true }}
                    render={({ field: { value, onChange, onBlur } }) => (
                      <Box>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                          <Typography>{t('FORM.email_label')}</Typography>
                          <Typography
                            variant='body2'
                            component={LinkStyled}
                            href='/forgot-password'
                            sx={{ textDecoration: 'none' }}
                          >
                            {t('forgot_password')}
                          </Typography>
                        </Box>

                        <CustomTextField
                          fullWidth
                          autoFocus
                          value={value}
                          onBlur={onBlur}
                          onChange={onChange}
                          placeholder={t('FORM.email_placeholder')}
                          error={Boolean(errors.email)}
                          {...(errors.email && { helperText: errors.email.message })}
                        />
                      </Box>
                    )}
                  />

                  <Controller
                    name='password'
                    control={control}
                    rules={{ required: true }}
                    render={({ field: { value, onChange, onBlur } }) => (
                      <CustomTextField
                        fullWidth
                        value={value}
                        onBlur={onBlur}
                        label={t('FORM.password_label')}
                        onChange={onChange}
                        id='auth-login-v2-password'
                        error={Boolean(errors.password)}
                        placeholder={t('FORM.password_placeholder')}
                        {...(errors.password && { helperText: errors.password.message })}
                        type={showPassword ? 'text' : 'password'}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position='end'>
                              <IconButton
                                edge='end'
                                onMouseDown={e => e.preventDefault()}
                                onClick={() => setShowPassword(!showPassword)}
                              >
                                <Icon fontSize='1.25rem' icon={showPassword ? 'tabler:eye' : 'tabler:eye-off'} />
                              </IconButton>
                            </InputAdornment>
                          )
                        }}
                      />
                    )}
                  />
                  <Box
                    sx={{
                      display: 'flex',
                      flexWrap: 'wrap',
                      alignItems: 'center',
                      justifyContent: 'space-between'
                    }}
                  >
                    <StyledFormControlLabel
                      label={t('FORM.remember_me_label')}
                      control={<Checkbox checked={rememberMe} onChange={e => setRememberMe(e.target.checked)} />}
                    />
                  </Box>
                  <Button fullWidth type='submit' variant='contained'>
                    {t('BUTTON.login')}
                  </Button>

                  {configuration?.allowed_register && (
                    <Stack
                      alignItems='center'
                      flexWrap='wrap'
                      justifyContent='center'
                      flexDirection='row'
                      gap={theme.spacing(2)}
                    >
                      {loginSettings?.show_registration_title && (
                        <Typography sx={{ color: 'text.secondary' }}>
                          {loginSettings?.registration_title || t('registration_title')}
                        </Typography>
                      )}

                      {configuration?.allowed_register && (
                        <Typography href='/register' component={LinkStyled}>
                          {t('create_account')}
                        </Typography>
                      )}
                    </Stack>
                  )}

                  <Stack direction='row' gap={theme.spacing(4)} justifyContent={'center'}>
                    {getGDPRLinks(portalSettings?.settings_imprint_gdrp?.[0]?.show_terms_page, router.basePath).map(
                      ({ title, url }) => (
                        <Typography
                          key={title}
                          component={'a'}
                          href={url}
                          target='_blank'
                          rel='noopener noreferrer'
                          variant='body1'
                          sx={{ textDecoration: 'none' }}
                        >
                          {tGlobal(title)}
                        </Typography>
                      )
                    )}
                  </Stack>
                </Stack>

                {/* TODO: the auth with social networks is not implemented yet */}
                {/* <Divider
                sx={{
                  color: 'text.disabled',
                  '& .MuiDivider-wrapper': { px: 6 },
                  fontSize: theme.typography.body2.fontSize,
                  my: theme => `${theme.spacing(6)} !important`
                }}
              >
                or
              </Divider> */}
                {/* <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <IconButton
                  href='/'
                  component={Link}
                  sx={{ color: '#497ce2' }}
                  onClick={(e: MouseEvent<HTMLElement>) => e.preventDefault()}
                >
                  <Icon icon='mdi:facebook' />
                </IconButton>
                <IconButton
                  href='/'
                  component={Link}
                  sx={{ color: '#1da1f2' }}
                  onClick={(e: MouseEvent<HTMLElement>) => e.preventDefault()}
                >
                  <Icon icon='mdi:twitter' />
                </IconButton>
                <IconButton
                  href='/'
                  component={Link}
                  onClick={(e: MouseEvent<HTMLElement>) => e.preventDefault()}
                  sx={{ color: theme => (theme.palette.mode === 'light' ? '#272727' : 'grey.300') }}
                >
                  <Icon icon='mdi:github' />
                </IconButton>
                <IconButton
                  href='/'
                  component={Link}
                  sx={{ color: '#db4437' }}
                  onClick={(e: MouseEvent<HTMLElement>) => e.preventDefault()}
                >
                  <Icon icon='mdi:google' />
                </IconButton>
              </Box> */}
              </form>
            </Box>
          </Box>
        </RightWrapper>

        <GDPRBanner
          showLargeBanner={gdpr?.show_large_banner}
          showSmallBanner={gdpr?.show_small_banner}
          smallBannerContent={{
            bannerText: gdpr?.gdpr_banner_text,
            acceptButtonLabel: gdpr?.gdpr_banner_accept_label,
            rejectButtonLabel: gdpr?.gdpr_banner_reject_label
          }}
          largeBannerContent={{
            largeBannerType: gdpr?.large_banner_type_id as LargeBannerTypeId,
            cookieText: gdpr?.gdpr_banner_main_text,
            detailsText: gdpr?.gdpr_banner_detail_text,
            aboutText: gdpr?.gdpr_banner_about_text
          }}
        />
      </Box>
    </>
  )
}

LoginPage.getLayout = (page: ReactNode) => <BlankLayout>{page}</BlankLayout>

LoginPage.guestGuard = true

export const getServerSideProps: GetServerSideProps = async context => {
  context.res.setHeader('Cache-Control', 'no-store, must-revalidate')

  try {
    const metaData = await fetchMetaData(context)

    if (!metaData) {
      return { props: { metaData: DEFAULT_METADATA } }
    }

    return { props: { metaData } }
  } catch (error) {
    return { props: { metaData: DEFAULT_METADATA } }
  }
}

export default LoginPage
